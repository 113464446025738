import React, { useEffect, useRef } from 'react';
import HomeEntryIntro from './HomeEntryIntro';
import HomeEntryText from './HomeEntryText';
import ImageBlock from '../ImageBlock';
import VideoBlock from '../VideoBlock';
import { Inject } from '../../lib/Inject';
import useWindowSize from '../../lib/useWindowSize';

const HomeEntry = ({
  image, video, text, i, isLogoShown
}) => {
  const { viewportW } = useWindowSize();
  if (text && i > 0) {
    return (
      <HomeEntryText
        paragraph={text.paragraph}
        callToAction={text.callToAction}
        isAnimated={true}
        threshold={0}
      />
    );
  }

  if (text && i === 0 && isLogoShown) {
    return <HomeEntryIntro paragraph={text.paragraph} callToAction={text.callToAction} />;
  }

  if (text && i === 0 && !isLogoShown && text.enabled) {
    return (
      <HomeEntryText
        paragraph={text.paragraph}
        callToAction={text.callToAction}
        isAnimated={true}
        threshold={0}
      />
    );
  }

  if (video && video.videoLink) {
    return (
      <VideoBlock
        vimeoLink={video.videoLink}
        placeholder={
          video.placeholder.placeholderImager ? video.placeholder.placeholderImager[0].url : null
        }
        hasMargin={false}
        fill={video.fill}
        autoplay={video.autoplay}
        controls={true}
        alignment={'center'}
        customPlayText={video.customPlayText}
        customPauseText={video.customPauseText}
        playFullscreen={viewportW < 1024}
      />
    );
  }

  if (image && image.imageImager.length) {
    return (
      <ImageBlock
        title={image.title}
        imageImager={image.imageImager}
        hasMargin={false}
        alignment={'center'}
        noAnimation={false}
      />
    );
  }
  return null;
};

export default Inject((store, ownProps) => ({
  isLogoShown: store.isLogoShown
}))(HomeEntry);
