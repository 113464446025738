import React, { useEffect } from "react";
import { Inject } from "../../lib/Inject";

const initialInverse = [];
let checkInverseTimeout;

function ProjectDetailScroller({
  inverseHeader,
  setFill,
  isFilled,
  isHeroScrolled,
  setIsHeroScrolled,
  setInverse,
  scrollTop,
  isTabletLandscapeDown,
  viewportH,
}) {
  if (scrollTop <= viewportH && !isTabletLandscapeDown) {
    if (isFilled) {
      setFill(false);
      setInverse(inverseHeader);
      return null;
    }
  } else {
    setFill(true);
    setInverse(false);
  }

  if (scrollTop <= viewportH + 50 && isHeroScrolled) {
    setIsHeroScrolled(false);
  }

  if (scrollTop > viewportH + 50 && !isHeroScrolled) {
    setIsHeroScrolled(true);
  }

  return null;
}

export default Inject((store, ownProps) => ({
  setFill: store.setFill,
  isFilled: store.isFilled,
  isHeroScrolled: store.isHeroScrolled,
  setIsHeroScrolled: store.setIsHeroScrolled,
  isInverse: store.isInverse,
  setInverse: store.setInverse,
  scrollTop: store.scrollTop,
  viewportH: store.viewportH,
  isTabletLandscapeDown: store.isTabletLandscapeDown,
}))(ProjectDetailScroller);
