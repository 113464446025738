// ****************************************/
// Contact
// ****************************************/

import styled from 'styled-components';
import { font, media } from '../Styles';

const ContactStyles = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.black}
  z-index: 3;

  &.inverse p,
  &.inverse a {
    color: white;
  }

  & > section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .block-text {
    text-align: center;
    flex: 1;
    width: 54rem;
    ${media.tabletLandscapeAndBelow`
			width: 330px;
			margin: auto;
		`}
    ${media.mobileOnly`
			width: auto;
			margin-left: 40px;
			margin-right: 40px;
		`}
  }

  .block-text + .block-text {
    margin-top: 3.5rem;
  }

  p,
  a {
    font-size: ${font.h2};
    letter-spacing: 0.02rem;
    color: white;
    ${media.tabletLandscapeAndBelow`
 			font-size: ${font.h2Mobile};
			letter-spacing: 0.2px;
	 	`};
  }

  p {
    line-height: 3.2rem;
    ${media.tabletLandscapeAndBelow`
			line-height: 1.33;
	 	`};
  }

  a {
    display: inline-block;
  }

  a:hover {
    color: ${props => props.theme.grey};
  }
`;

export default ContactStyles;
