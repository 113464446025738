import React, { useContext, useState, useEffect } from 'react';
import { observer, useObservable } from 'mobx-react-lite';
import HeaderStyles from './Header.styled';
import HeaderChild from './HeaderChild';
import { Inject } from '../../lib/Inject';

function Header({
  isInverse,
  isFilled,
  isHeaderHidden,
  isHeaderAnimated,
  location,
  isLogoAnimating,
  isVideoFullScreen
}) {
  const isHidden = location.pathname === '/' && (isHeaderHidden || isLogoAnimating);
  return (
    <HeaderStyles
      className={`${isInverse ? 'is-inverse' : ''} ${isHidden ? 'is-hidden' : ''} ${
        isFilled ? 'is-filled' : ''
      } ${isHeaderAnimated ? 'is-animated' : ''} ${isVideoFullScreen ? 'is-video-fullscreen' : ''}`}
    >
      <HeaderChild />
    </HeaderStyles>
  );
}
export default Inject((store, ownProps) => ({
  isLogoAnimating: store.isLogoAnimating,
  isInverse: store.isInverse,
  isFilled: store.isFilled,
  isHeaderAnimated: store.isHeaderAnimated,
  isHeaderHidden: store.isHeaderHidden,
  isVideoFullScreen: store.isVideoFullScreen
}))(Header);
