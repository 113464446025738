import React, { useEffect, useState, useRef } from "react";
import { relative } from "path";
import styled from "styled-components";
import { IsNumeric } from "../../lib/Utils";
import { FigureStyles, Picture, ImageStyles } from "./Image.styled";

require("intersection-observer");

function Image(props) {
  let observer;
  const elem = useRef();
  const {
    src,
    srcWebP,
    srcWebPMobile,
    srcMobile,
    alt,
    noLazyLoad,
    isAnimated,
    threshold,
    width,
    height
  } = props;
  const config = {
    rootMargin: "0px",
    threshold: threshold !== undefined ? threshold : 0
  };
  const [isActive, setIsActive] = useState(false);
  const w = parseInt(width, 10);
  const h = parseInt(height, 10);

  let paddingBottom;

  if (IsNumeric(w) && IsNumeric(h)) {
    paddingBottom = `${(h / w) * 100}%`;
  }

  const Figure = styled(FigureStyles)`
    padding-bottom: ${paddingBottom || "initial"};
  `;

  let style;
  if (isAnimated) {
    if (isActive) {
      style = "animate is-animated";
    } else {
      style = "is-animated";
    }
  }

  const makeActive = () => {
    setIsActive(true);
  };

  const makeInactive = () => {
    setIsActive(false);
  };

  const onIntersection = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (!isActive) {
          makeActive();
        }
        removeObserver();
      }
    });
  };

  const createObserver = () => {
    if (!elem.current) {
      return;
    }
    observer = new IntersectionObserver(entries => {
      onIntersection(entries);
    }, config);

    observer.observe(elem.current);
  };

  useEffect(() => {
    createObserver();
    return () => {
      removeObserver();
    };
  }, []);

  const removeObserver = () => {
    if (observer && elem.current) {
      observer.unobserve(elem.current);
    }
    observer = undefined;
  };

  return (
    <Figure ref={elem} className={style}>
      <Picture>
        {/* {srcWebP && !srcWebPMobile && <source srcSet={srcWebP} type="image/webp" />}
        {srcWebP && srcWebPMobile && (
          <source media="(min-width: 451px)" srcSet={srcWebP} type="image/webp" />
        )}
        {srcWebPMobile && (
          <source media="(max-width: 750px)" srcSet={srcWebPMobile} type="image/webp" />
        )} */}
        <source
          media={srcMobile && "(min-width: 451px)"}
          srcSet={src}
          type="image/jpeg"
        />
        {srcMobile && (
          <source
            media="(max-width: 750px)"
            srcSet={srcMobile}
            type="image/jpeg"
          />
        )}
        {(noLazyLoad || isActive) && <ImageStyles src={src} alt={alt} />}
      </Picture>
    </Figure>
  );
}

export default React.memo(Image);
