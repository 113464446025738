import React, { useEffect, useRef, useState } from 'react';
import { useChain, useSpring, animated } from 'react-spring';
import delay from 'delay';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import ProjectCardStyles from './ProjectCard.styled';
import { Inject } from '../../lib/Inject';

app = window.app = window.app || {};

function ProjectCard({
  heightStart,
  heightEnd,
  colour,
  description,
  isCardWipeStart,
  setCardWipeStart,
  onComplete
}) {
  const [frontProps, frontSet] = useSpring(() => ({
    from: {
      clipPath: `inset(${heightStart}% 0 0 0)`,
      WebkitClipPath: `inset(${heightStart}% 0 0 0)`,
      opacity: 0
    }
  }));

  const [backProps, backSet] = useSpring(() => ({
    from: {
      opacity: 0
    }
  }));

  useEffect(() => {
    if (isCardWipeStart) {
      backSet({
        to: async (next, cancel) => {
          disableBodyScroll();
          await next({ opacity: 1 });
          await delay(1500);
          await next({ opacity: 0 });
        }
      });
      frontSet({
        to: async (next, cancel) => {
          await next({ opacity: 1, clipPath: 'inset(0 0 0 0)', WebkitClipPath: 'inset(0 0 0 0)' });
          await delay(1600);
          await next({ opacity: 0, clipPath: 'inset(0 0 0 0)', WebkitClipPath: 'inset(0 0 0 0)' });
          enableBodyScroll();
          await next({
            opacity: 0,
            clipPath: `inset(${heightStart}% 0 0 0)`,
            WebkitClipPath: `inset(${heightStart}% 0 0 0)`
          });
          setCardWipeStart(false);
          app.events.publish('card.animated');
          onComplete();
        }
      });
    }
  }, [isCardWipeStart]);

  return (
    <ProjectCardStyles className="project-next-card">
      <animated.section className="front-layer-clip" style={frontProps}>
        <section className="front-layer" style={{ background: colour }}>
          <p>{description || ''}</p>
        </section>
      </animated.section>
      <animated.section className="back-layer" style={backProps} />
    </ProjectCardStyles>
  );
}

export default Inject((store, ownProps) => ({
  isCardWipeStart: store.isCardWipeStart,
  setCardWipeStart: store.setCardWipeStart
}))(ProjectCard);
