import React, { Suspense } from 'react';
import ImageWideBlock from './ImageWideBlock';
import fetch from '../../lib/fetch';

function ImageWideBlockQuery({ id, isLastProject }) {
  const href = isLastProject ? '/api/project/first.json' : `/api/project/next/${id}.json`;

  const response = fetch({
    href,
    method: 'GET',
    body: null,
    cache: 'no-cache'
  });

  const {
    uri, title, wideFormatImage, colour
	} = response;
	
	if(!response || !wideFormatImage){
		return null;
	}

  return (
    <ImageWideBlock
      uri={uri}
      title={title}
      wideFormatImageImager={wideFormatImage.wideFormatImageImager}
      colour={colour}
    />
  );
}

function ImageWideBlockContainer({ id, isLastProject }) {
  return (
    <Suspense fallback={null}>
      <ImageWideBlockQuery id={id} isLastProject={isLastProject} />
    </Suspense>
  );
}

export default ImageWideBlockContainer;
