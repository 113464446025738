import {
  observable, decorate, computed, action
} from 'mobx';
import { createContext } from 'react';
import { Debounce } from '../lib/Utils';

class AppStore {
  constructor() {
    this.isInverse = false;
    this.isLogoShown = this.shouldShowLogo();
    if (this.isLogoShown) {
      const object = { value: true, timestamp: new Date().getTime() };
      window.localStorage.setItem('POSTLogoShown', JSON.stringify(object));
    }
    this.isHeroScrolled = false;
    this.isFilled = false;
    this.isLogoAnimating = this.isLogoShown;
    this.isHeaderAnimated = false;
    this.isHeaderHidden = false;
    this.lastScrollTop = 0;
    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.scrollDirection = 'down';
    this.ticking = false;
    this.isCardWipeStart = undefined;
    this.cardWipeUri = undefined;
    this.isProjectPage = false;
    this.firstProject = undefined;
    this.lastProject = undefined;
    this.lastProjectUri = undefined;
    const [html, body] = [document.documentElement, document.body];
    this.isVideoFullScreen = undefined;

    this.viewportW = Math.max(
      window.innerWidth || html.clientWidth || body.clientWidth || window.screen.availWidth
    );
    this.viewportH = Math.max(
      window.innerHeight || html.clientHeight || body.clientHeight || window.screen.availHeight
    );
    this.isMobileDown = this.viewportW < 520;
    this.isTabletPortraitDown = this.viewportW < 769;
    this.isTabletLandscapeDown = this.viewportW < 1025;
    if (this.isTabletPortraitDown || this.isTabletPortraitDown) {
      this.isTabletDown = true;
    } else {
      this.isTabletDown = false;
    }
    this.isXlUp = this.viewportW > 1999;
    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', this.handleResize);
  }

  setIsHeroScrolled = val => {
    this.isHeroScrolled = val;
  };

  setInverse = val => {
    this.isInverse = val;
  };

  setFill = val => {
    this.isFilled = val;
  };

  setHeaderAnimated = val => {
    this.isHeaderAnimated = val;
  };

  scrollHandler = () => {
    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.ticking = true;
        this.onScroll();
      });
    }
  };

  onScroll = () => {
    this.ticking = false;
    if (this.lastScrollTop < this.scrollTop) {
      this.scrollDirection = 'down';
    } else if (this.lastScrollTop > this.scrollTop) {
      this.scrollDirection = 'up';
    }
    this.lastScrollTop = this.scrollTop;
  };

  handleResize = () => {
    const [html, body] = [document.documentElement, document.body];
    const width = Math.max(
      window.innerWidth || html.clientWidth || body.clientWidth || window.screen.availWidth
    );
    const height = Math.max(
      window.innerHeight || html.clientHeight || body.clientHeight || window.screen.availHeight
    );
    this.setDimensions(width, height);
  };

  setDimensions = (width, height) => {
    this.viewportW = width;
    this.viewportH = height;
    this.getDeviceState();
  };

  setCardWipeStart = val => {
    this.isCardWipeStart = val;
  };

  setCardWipeUri = uri => {
    this.cardWipeUri = uri;
  };

  getDeviceState = () => {
    this.isMobileDown = this.viewportW < 520;
    this.isTabletPortraitDown = this.viewportW < 769;
    this.isTabletLandscapeDown = this.viewportW < 1025;
    if (this.isTabletPortraitDown || this.isTabletPortraitDown) {
      this.isTabletDown = true;
    } else {
      this.isTabletDown = false;
    }
    this.isXlUp = this.viewportW > 1999;
  };

  setHeaderHidden = val => {
    this.isHeaderHidden = val;
  };

  setLogoShown = val => {
    this.isLogoShown = val;
  };

  shouldShowLogo = () => {
    if (window.localStorage && localStorage.getItem('POSTLogoShown') === null) {
      return true;
    }
    const object = JSON.parse(window.localStorage.getItem('POSTLogoShown'));
    const dateString = object.timestamp;
    const now = new Date().getTime().toString();
    const timeDiff = Math.abs(dateString - now);
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (diffDays > 5) {
      return true;
    }
    return false;
  };

  setIsLogoAnimating = val => {
    this.isLogoAnimating = val;
  };

  setIsProjectPage = bool => {
    this.isProjectPage = bool;
  };

  setLastProject = val => {
    this.lastProject = val;
  };

  setLastProjectUri = val => {
    this.lastProjectUri = val;
  };

  setFirstProject = val => {
    this.firstProject = val;
  };

  toggleFullScreen = () => {
    this.isVideoFullScreen = this.isVideoFullScreen !== undefined ? !this.isVideoFullScreen : true;
    console.log(this.isVideoFullScreen);
  };
}

decorate(AppStore, {
  isInverse: observable,
  scrollTop: observable,
  isVideoFullScreen: observable,
  isFilled: observable,
  isCardWipeStart: observable,
  isHeroScrolled: observable,
  cardWipeUri: observable,
  isHeaderAnimated: observable,
  isHeaderHidden: observable,
  isLogoAnimating: observable,
  viewportW: observable,
  viewportH: observable,
  isMobileDown: observable,
  isTabletPortraitDown: observable,
  isTabletDown: observable,
  isXlUp: observable,
  isLogoShown: observable,
  isProjectPage: observable,
  firstProject: observable,
  lastProject: observable,
  lastProjectUri: observable,
  setInverse: action,
  setIsHeroScrolled: action,
  setHeaderAnimated: action,
  setFill: action,
  setDimensions: action,
  setCardWipeStart: action,
  setCardWipeUri: action,
  setHeaderHidden: action,
  shouldShowLogo: action,
  setIsLogoAnimating: action,
  setLogoShown: action,
  setIsProjectPage: action,
  setLastProject: action,
  setLastProjectUri: action,
  setFirstProject: action,
  toggleFullScreen: action
});

export default createContext(new AppStore());
