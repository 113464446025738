// ****************************************/
// Video Player Controls
// ****************************************/

import styled from 'styled-components';
import { media, font, animation } from '../Styles';

const VideoPlayerControlsStyles = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;

  .chrome-controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ui-controls,
  .chrome-controls,
  .play-btn {
    height: 100%;
    width: 100%;
  }

  .ui-controls {
    opacity: 1;
    transform: opacity 0.25s ease-in-out;
  }

  .ui-controls.is-hidden {
    opacity: 0;
  }

  .chrome-controls button {
    position: relative;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
  }

  .chrome-controls button span {
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: ${font.h1};
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.h1Tablet}
		`}
    ${media.mobileOnly`
			font-size: ${font.h1Mobile}
		`}
  }
`;
export default VideoPlayerControlsStyles;
