import React, {
  Component, useEffect, useRef, useState
} from 'react';
import { Vimeo } from 'vimeo';
import Video from './Video';
import VideoEmbed from './VideoEmbed';
import { vimeoClientIdentifier, vimeoClientSecret, vimeoAccessToken } from '../../config';
import { Inject } from '../../lib/Inject';

function VideoContainer({
  link,
  inView,
  embed,
  placeholder,
  posterPosition,
  posterFillMode,
  hd,
  controls,
  autoplay,
  fill,
  threshold,
  customPlayText,
  customPauseText,
  playOnHover,
  playFullscreen,
  toggleFullScreen
}) {
  const [request, setRequest] = useState({
    loaded: false,
    file: undefined,
    poster: undefined
  });
  let data;
  let videoId;
  const elem = useRef();

  const getVimeo = id => new Promise((resolve, reject) => {
    const client = new Vimeo(vimeoClientIdentifier, vimeoClientSecret, vimeoAccessToken);
    client.request(
      {
        path: `/me/videos/${id}?fields=privacy.download,pictures.sizes.link,download.link,files.quality,files.link`
      },
      (err, data, responseStatus, headers) => {
        if (err || !data) {
          console.log(responseStatus);
          reject(new DOMException(`Error: ${err}`));
          return;
        }
        resolve(data);
      }
    );
  });

  const save = response => {
    if (!window.localStorage || !response) {
      return;
    }
    data = response;
    let obj = {};
    if (localStorage.POST) {
      obj = JSON.parse(localStorage.POST);
    }
    obj[videoId] = data;
    localStorage.POST = JSON.stringify(obj);
  };

  const getLocal = () => {
    if (!window.localStorage || !localStorage.POST) {
      return undefined;
    }
    const obj = JSON.parse(localStorage.POST);
    return obj[videoId];
  };

  const getIdFromVideo = link => {
    // https://www.youtube.com/watch?v=YSuo0j2xsj8
    if (link.match(/(\?|&)v=([^&#]+)/)) {
      return link.match(/(\?|&)v=([^&#]+)/).pop();
    }
    // http://youtu.be/YSuo0j2xsj8
    if (link.match(/(\.be\/)+([^\/]+)/)) {
      return link.match(/(\.be\/)+([^\/]+)/).pop();
    }
    // www.youtube.com/embed/YSuo0j2xsj8
    if (link.match(/(\embed\/)+([^\/]+)/)) {
      const ID = link.match(/(\embed\/)+([^\/]+)/);
      return ID.pop().replace('?rel=0', '');
    }
    // vimeo.com/9202349204
    if (link.match(/vimeo.*\/(\d+)/i)) {
      return link.match(/vimeo.*\/(\d+)/i)[1];
    }
    return link;
  };

  const get = id => new Promise((resolve, reject) => {
    if (!videoId) {
      reject(new Error('no video id'));
    }
    const local = getLocal();
    if (local) {
      data = local;
      resolve();
    }
    try {
      getVimeo(videoId)
        .then(response => {
          save(response);
          resolve();
        })
        .catch(e => {
          console.log(e);
          reject(new Error('failed to get video from vimeo'));
        });
    } catch (e) {
      console.log(e);
      reject(new Error('failed to get video from vimeo'));
    }
  });

  async function fetchApi() {
    videoId = getIdFromVideo(link);
    try {
      await get(videoId);
    } catch (e) {
      console.log(e);
    }
    if (!data) {
      console.log(data);
      return;
    }
    const { sizes } = data.pictures;
    const file = data.files[data.files.length - 1];
    const poster = sizes[sizes.length - 1];
    setRequest({
      loaded: true,
      file,
      poster
    });
  }

  useEffect(() => {
    if (link) {
      fetchApi();
    }
  }, []);

  if (request.loaded && link) {
    return (
      <Video
        inView={inView}
        url={request.file.quality === 'hls' && request.file.link}
        hd={hd}
        poster={request.poster && request.poster.link}
        posterFillMode={posterFillMode}
        posterPosition={posterPosition}
        autoplay={autoplay}
        controls={controls}
        threshold={threshold}
        fill={fill}
        placeholder={placeholder}
        customPlayText={customPlayText}
        customPauseText={customPauseText}
        playOnHover={playOnHover}
        playFullscreen={playFullscreen}
        toggleFullScreen={toggleFullScreen}
      />
    );
  }

  return <VideoEmbed embed={embed} />;
}

export default Inject((store, ownProps) => ({
  toggleFullScreen: store.toggleFullScreen
}))(VideoContainer);
