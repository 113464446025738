import React from 'react';
import useFetch from 'fetch-suspense';

const Fetch = ({
  href, method, body, cache
}) => {
  const headers = new Headers({
    Accept: '*/*',
    'X-Requested-With': 'XMLHttpRequest'
  });

  const request = {
    method,
    mode: 'same-origin',
    credentials: 'include',
    headers,
    body,
    cache
  };

  const data = useFetch(href, request);
  return data;
};

export default Fetch;
