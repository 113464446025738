// ****************************************/
// Projects
// ****************************************/

import styled from "styled-components";
import { animated } from "react-spring";
import { font, media, animation } from "../Styles";

const ProjectsStyles = styled.section`
  position: relative;
  z-index: 3;
  margin-top: 12rem;
  padding-left: 5.5rem;
  padding-right: 5.5rem;
  padding-bottom: 7rem;
  font-size: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  background: ${(props) => props.theme.white};;
  margin-bottom: 31.4rem;
  min-height: 100vh;
  ${media.tabletPortraitAndBelow`
		margin-top: 80px;
		display: block;
		flex-flow: initial;
	`}
  ${media.mobileOnly`
			margin-bottom: 56.6vw;
		`}


  .video-block .iframe-wrapper {
    position: relative;
    padding-bottom: 66%;
    overflow: hidden;
  }

  .video-block .iframe-wrapper iframe {
    position: absolute;
    background: ${(props) => props.theme.black}
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 151.5151515152%; /* 2000/1320*100%  or width/height * 100% */
  }

  .project-container {
    width: calc(50% - 2.25rem);
    padding-bottom: 5.3rem;
    z-index: 2;
    ${media.smallDesktopAndBelow`
			padding-bottom: 40px;
		`}
    ${media.tabletPortraitAndBelow`
			width: 100%;
	`}
  }

  .project-container figure {
    position: relative;
  }

  .project-container picture {
    position: absolute;
    top: 0;
    left: 0;
  }

  .project-container h2 {
    text-transform: uppercase;
    margin-bottom: 0.55rem;
  }

  .project-container h2,
  .project-container p {
    font-size: ${font.p};
    letter-spacing: 0.02rem;
    ${media.smallDesktopAndBelow`
 			font-size: ${font.pMobile};
			letter-spacing: 0.2px;
	 	`};
  }

  .project-container p {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
		${media.tabletLandscapeAndBelow`
			opacity: 1;
		`}
  }

  .project-container:hover p {
    opacity: 1;
  }

  .project-container a > section {
    padding-bottom: 2.5rem;
  }

  .project-container p {
    color: ${(props) => props.theme.grey};
    line-height: 1.2;
  }

  ${animation.fadeIn}

  figure.is-animated.animate {
    animation: fadeIn 0.4s ease-in-out;
  }

	picture, img {
		${media.tabletLandscapeAndBelow`
			height: 100%;
			object-fit: cover;
		`}
	}

  .video-block figure {
    padding-bottom: 60%;
  }
`;

export default ProjectsStyles;
