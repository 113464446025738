// ****************************************/
// Project Detail
// ****************************************/

import styled from "styled-components";
import { animated } from "react-spring/renderprops";
import { font, media } from "../Styles";

const ProjectDetailStyles = styled(animated.section)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  overflow: hidden;

  .content-wrap {
    position: relative;
    background: ${(props) => props.theme.white};;
    z-index: 3;
    top: 100vh;
    margin-bottom: calc(100vh - 1.1rem);
    min-height: 105vh;
    ${media.tabletPortraitAndBelow`
			top: initial;
			min-height: initial;
			margin-bottom: initial;
		`}
  }

  .featured {
		background: ${(props) => props.theme.grey100};
    ${media.tabletLandscapeAndBelow`
			margin-top: 60px;
		`}
    ${media.mobileOnly`
			margin-top: 50px;
		`}
  }

  .video-block,
  .double-image-block,
  .image-block {
    padding-top: 11.4rem;
    ${media.tabletLandscapeAndBelow`
			padding-top: 95px;
		`}
    ${media.mobileOnly`
			padding-top: 60px;
		`}
  }

  & .text-block + section,
  .image-wide-block .image-block,
  .image-block.featured,
  .video-block.featured {
    padding-top: initial;
  }

  .video-block .iframe-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.666666666667%;
  }

  .video-block .iframe-wrapper iframe {
    position: absolute;
    background: ${(props) => props.theme.black}
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  .text-block + .layout,
  .layout + .text-block {
    margin-top: initial !important;
  }

  .project-card {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #465539;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  .project-card p {
    font-size: ${font.h1};
    line-height: 1.25;
    color: white;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.h1Mobile}
		`}
  }

  .image-wide-block .image-block,
  .image-wide-block .image-block > section {
    ${media.mobileOnly`
			width: 100%;
			height: 100%;
		`}
  }

	.text-block {
		background: ${(props) => props.theme.white};;
	}

	.text-block.large.center p{
		${media.tabletLandscapeAndBelow`
			width: 100%;
		`}
	}


`;

export default ProjectDetailStyles;
