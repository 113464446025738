// ****************************************/
// Project Card
// ****************************************/

import styled from "styled-components";
import { animated } from "react-spring";
import { font, media } from "../Styles";

const ProjectCardStyles = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: none;

  .front-layer-clip {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .front-layer {
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    text-align: center;
  }

  .back-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: ${(props) => props.theme.white};
  }

  & p {
    font-size: ${font.h1};
    line-height: 1.25;
    color: white;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.h1Mobile}
		`}
  }
`;

export default ProjectCardStyles;
