import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

const HeaderChild = React.memo(() => (
  <>
    <section>
      <h1>
        <Link to="/">Post</Link>
      </h1>
    </section>
    <section>
      <Link to="/projects">Projects</Link>
      <Link to="/about">About</Link>
      <Link to="/contact">Contact</Link>
    </section>
  </>
));

export default HeaderChild;
