import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Image from "../Image";
import VideoBlock from "../VideoBlock";

function Project({
  title,
  uri,
  thumbnailImageImager,
  thumbnailVideo,
  projectDescription
}) {
  const rawMarkup = props => {
    const markup = props;
    return { __html: markup };
  };

  return (
    <section className="project-container">
      <Link to={uri}>
        <section>
          {thumbnailImageImager.length ? (
            <Image
              noLazyLoad={false}
              isAnimated={true}
              alt={title}
              src={thumbnailImageImager[0].url}
              srcMobile={thumbnailImageImager[1].url}
              // srcWebP={thumbnailImageImager[2].url}
              width={thumbnailImageImager[0].width}
              height={thumbnailImageImager[0].height}
            />
          ) : null}
          {thumbnailVideo ? (
            <VideoBlock
              autoplay={true}
              playOnHover={false}
              vimeoLink={thumbnailVideo}
              placeholder={
                thumbnailImageImager.length ? thumbnailImageImager : null
              }
              fill={true}
              hasMargin={false}
              alignment={"center"}
            />
          ) : null}
        </section>
        <h2>{title}</h2>
        <p
          dangerouslySetInnerHTML={
            projectDescription && rawMarkup(projectDescription.content)
          }
        />
      </Link>
    </section>
  );
}

export default Project;
