// ****************************************/
// Video Block
// ****************************************/

import styled from 'styled-components';
import { font, media, underline } from '../Styles';

const VideoBlockStyles = styled.section`
  text-align: center;

  .iframe-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &.featured {
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 100vh;
    ${media.tabletPortraitAndBelow`
			position: relative;
			height: 56.25vw;
		`}
  }

  &.featured video {
    object-fit: cover;
  }

  &.featured.is-hero-scrolled {
    z-index: 2;
  }

  &.has-margin {
    margin: 0 5.5rem;
    ${media.tabletLandscapeAndBelow`
			margin-left: 60px;
			margin-right: 60px;
		`}
    ${media.mobileOnly`
			margin-left: 20px;
			margin-right: 20px;
		`}
  }

  &.large {
    width: 100%;
  }

  &.small {
    width: 102.4rem;
    ${media.smallDesktopAndBelow`
			width: 100%;
		`}
  }

  &.left {
    margin-right: auto;
  }

  &.right {
    margin-left: auto;
  }

  p {
    font-size: ${font.p};
    line-height: 1.33;
    margin-top: 2.6rem;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.pMobile}
			margin-top: 13px;
		`}
    ${media.mobileOnly`
			margin-top: 6px;
		`}
  }

  p a:hover {
    color: ${props => props.theme.grey};
  }
`;

export default VideoBlockStyles;
