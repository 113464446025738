import React, {
  Fragment,
  Suspense,
  useRef,
  useState,
  useEffect,
  useCallback
} from "react";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import HomeStyles from "./Home.styled";
import HomeEntry from "./HomeEntry";
import HomeScrollSnap from "./HomeScrollSnap";
import HomeScrollInverse from "./HomeScrollInverse";
import { Inject } from "../../lib/Inject";
import Footer from "../Footer";
import fetch from "../../lib/fetch";

const HomeContainer = ({ isVideoFullScreen, setFill, setIsProjectPage }) => {
  const [elem, setElem] = React.useState(null);
  const href = `/api/home.json`;

  const response = fetch({
    href,
    method: "GET",
    body: null,
    cache: "no-cache"
  });

  if (!response) {
    return null;
  }

  const inverseArray = response.reduce((array, child, i) => {
    const imageInverse =
      child.image && child.image.image && child.image.inverseHeader;
    const videoInverse =
      child.video && child.video.videoLink && child.video.inverseHeader;

    if (imageInverse || videoInverse) {
      array.push(true);
      return array;
    }
    array.push(false);
    return array;
  }, []);

  useEffect(() => {
    if (isVideoFullScreen) {
      disableBodyScroll();
    } else if (isVideoFullScreen === false) {
      enableBodyScroll();
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  });

  return (
    <>
      <HomeStyles
        className={`Home ${isVideoFullScreen ? "is-video-fullscreen" : ""}`}
        ref={setElem}
      >
        {response.map((block, i) => (
          <HomeEntry {...block} key={i} i={i} />
        ))}
        <HomeScrollSnap elem={elem} />
        <HomeScrollInverse elem={elem} inverseArray={inverseArray} />
      </HomeStyles>
      <Footer />
    </>
  );
};

function Home({ isVideoFullScreen, setFill, setIsProjectPage }) {
  return (
    <Suspense fallback={null}>
      <HomeContainer
        setFill={setFill}
        setIsProjectPage={setIsProjectPage}
        isVideoFullScreen={isVideoFullScreen}
      />
    </Suspense>
  );
}

export default Inject((store, ownProps) => ({
  setFill: store.setFill,
  isVideoFullScreen: store.isVideoFullScreen,
  setIsProjectPage: store.setIsProjectPage
}))(Home);
