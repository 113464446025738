// ****************************************/
// About
// ****************************************/

import styled from "styled-components";
import { font, media } from "../Styles";

const AboutStyles = styled.section`
  position: relative;
  z-index: 3;
  background: ${(props) => props.theme.white};
  margin-bottom: 31.4rem;
  min-height: 100vh;
  ${media.mobileOnly`
		margin-bottom: 56.6vw;
	`}

  .intro {
    margin: 19.5rem 5.5rem;
    text-align: center;
    ${media.tabletLandscapeAndBelow`
			margin-left: 20%;
			margin-right: 20%;
		`}
    ${media.mobileOnly`
			margin: 110px 80px 90px 80px;
	`}
  }

  & > .image-block:nth-child(n + 3) {
    width: 75%;
    ${media.mobileOnly`
			width: 100%;
		`}
  }

  .image-block.center {
    padding: 0 5.5rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    ${media.smallDesktopAndBelow`
			padding: 0 55px;
		`}
    ${media.mobileOnly`
			padding: 0 20px;
		`}
  }

  .intro h2 {
    font-size: ${font.h1};
    line-height: 1.33;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.h1Tablet};
		`}
    ${media.mobileOnly`
			font-size: ${font.h1Mobile};
		`}
  }

  .intro + .image-block.has-margin {
    ${media.mobileOnly`
			margin-left: 0;
			margin-right: 0;
		`}
  }
`;

export default AboutStyles;
