import React, { Fragment, Suspense } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import Header from '../Header';
import TransitionFade from '../TransitionFade';
import ProjectCard from '../ProjectCard';
import Loader from '../Loader';
import RouterClickHandler from './RouterClickHandler';

const Router = () => (
  <>
    <BrowserRouter>
      <Route
        render={({ location, history }) => (
          <>
            <Header location={location} />
            <section className="flexwrap">
              <main
                onClick={e => {
                  RouterClickHandler(e, history);
                }}
              >
                <TransitionFade location={location} />
              </main>
            </section>
          </>
        )}
      />
    </BrowserRouter>
    <ProjectCard />
  </>
);

export default Router;
