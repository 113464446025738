import React, { Fragment, useRef, useEffect } from 'react';
import ImageBlock from '../ImageBlock';
import TextBlock from '../TextBlock';
import VideoBlock from '../VideoBlock';
import ImageDoubleBlock from '../ImageDoubleBlock';
import ImageWideBlock from '../ImageWideBlock';
import Carousel from '../Carousel';
import ProjectDetailScroller from './ProjectDetailScroller';
import { Inject } from '../../lib/Inject';

function Project({
  title,
  id,
  uri,
  firstProject,
  isLastProject,
  featuredImageImager,
  featuredVideo,
  layout,
  setInverse,
  setHeaderAnimated,
  inverseHeader,
  isHeroScrolled,
  setIsProjectPage
}) {
  const childRef = useRef();

  useEffect(() => {
    setIsProjectPage(true);
    setHeaderAnimated(true);
    return () => {
      setInverse(false);
      setHeaderAnimated(false);
    };
  }, []);

  const renderBlock = (block, i) => {
    if (block.image) {
      const value = Object.assign({}, block.image, { name: 'regular' });
      return <ImageBlock {...value} key={i} />;
    }
    if (block.text) {
      return <TextBlock block={block.text} key={i} threshold={0} isAnimated={true} />;
    }
    if (block.doubleImage) {
      const {
        caption, hasMargin, imageLeft, imageRight
      } = block.doubleImage;
      return (
        <ImageDoubleBlock
          caption={caption}
          hasMargin={hasMargin}
          imageLeftImager={imageLeft.imageLeftImager}
          imageRightImager={imageRight.imageRightImager}
          key={i}
        />
      );
    }
    if (block.video) {
      return (
        <VideoBlock
          vimeoLink={block.video.vimeoLink}
          hasMargin={block.video.hasMargin}
          autoplay={block.video.autoplay}
          placeholder={block.video.placeholderImager}
          videoCaption={block.video.videoCaption}
          alignment={'center'}
          key={i}
        />
      );
    }
    if (block.slideshow) {
      return <Carousel layout={block.slideshow.layout} caption={block.slideshow.caption} key={i} />;
    }
    return null;
  };

  return (
    <section className="project">
      {featuredImageImager && !featuredVideo ? (
        <ImageBlock
          noLazyLoad={true}
          imageImager={featuredImageImager}
          name={`featured ${isHeroScrolled ? 'is-hero-scrolled' : ''}`}
          noAnimation={false}
        />
      ) : null}
      {featuredVideo ? (
        <VideoBlock
          noLazyLoad={true}
          vimeoLink={featuredVideo}
          placeholder={featuredImageImager || null}
          hasMargin={false}
          alignment={'center'}
          autoplay={true}
          name={`featured ${isHeroScrolled ? 'is-hero-scrolled' : ''}`}
        />
      ) : null}
      <section className="content-wrap">{layout.map((block, i) => renderBlock(block, i))}</section>
      <ImageWideBlock
        id={!isLastProject ? id : firstProject}
        isLastProject={isLastProject}
        uri={uri}
      />
      <ProjectDetailScroller inverseHeader={inverseHeader} />
    </section>
  );
}

export default Inject((store, ownProps) => ({
  setInverse: store.setInverse,
  setFill: store.setFill,
  setHeaderAnimated: store.setHeaderAnimated,
  isHeroScrolled: store.isHeroScrolled,
  setIsProjectPage: store.setIsProjectPage
}))(Project);
