import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Image from "../Image";
import ImageDoubleBlockStyles from "./ImageDoubleBlock.styled";

function ImageDoubleBlock({
  imageLeftImager,
  imageRightImager,
  hasMargin,
  doubleImageCaption
}) {
  const rawMarkup = text => {
    const markup = text;
    return { __html: markup };
  };

  return (
    <ImageDoubleBlockStyles
      className={`double-image-block ${hasMargin ? "has-margin" : ""}`}
    >
      <section>
        <section>
          <Image
            noLazyLoad={false}
            isAnimated={true}
            // alt={imageLeft[0].title}
            src={imageLeftImager[0].url}
            srcMobile={imageLeftImager[1].url}
            // srcWebP={imageLeftImager[2].url}
            width={imageLeftImager[0].width}
            height={imageLeftImager[0].height}
          />
        </section>
        <section>
          <Image
            noLazyLoad={false}
            isAnimated={true}
            // alt={imageRight[0].title}
            src={imageRightImager[0].url}
            srcMobile={imageRightImager[1].url}
            // srcWebP={imageRightImager[2].url}
            width={imageRightImager[0].width}
            height={imageRightImager[0].height}
          />
        </section>
      </section>
      {doubleImageCaption ? (
        <p
          dangerouslySetInnerHTML={
            doubleImageCaption && rawMarkup(doubleImageCaption.content)
          }
        />
      ) : null}
    </ImageDoubleBlockStyles>
  );
}

export default ImageDoubleBlock;
