const theme = {
  black: "#0B0B0B",
  grey100: "#f9f9f9",
  grey: "#777777",
  white: "#ffffff",
  selectionHighlightColor: "#2F2223",
  selectionColor: "white",
  lightGrey: "rgba(0,0,0,0.1)",
  viewport: 1440, // define viewport of development macnine for accurate rem sizes
  baseline: 10,
};

export default theme;
