// ****************************************/
// Analytics
// ****************************************/

import styled from 'styled-components';
import { font, media } from '../Styles';
import { animated } from 'react-spring';

const AnalyticsStyles = styled(animated.section)`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: ${props => props.theme.black}
  padding: 12px 14px;
  z-index: 4;
	display: flex;
	align-items: center;
  justify-content: center;
	${media.tabletPortraitAndBelow`
		justify-content: flex-start;
	`}
	${media.tabletPortraitAndBelow`
		padding-left: 15px;
		padding-right: 50px;
	`}

  section {
		flex: 1
    display: flex;
    align-items: center;
    justify-content: center;
		${media.tabletPortraitAndBelow`
			justify-content: flex-start;
		`}
		${media.mobileOnly`
		  width: 330px;
		`}
  }

  button,
  p {
    color: white;
    font-size: ${font.p};
    ${media.smallDesktopAndBelow`
			font-size: ${font.pMobile};
		`}
  }

  p a {
    color: ${props => props.theme.grey};
  }
  
	p {
		${media.mobileOnly`
		  line-height: 1.2;
		`}
	}

  button {
    background: none;
    cursor: pointer;
    outline: none;
  }

  .accept-btn {
    border: 1px solid white;
    padding: 4px 10px;
    margin-left: 12px;
		transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
		${media.tabletLandscapeAndBelow`
			margin-right: auto;
		`}
  }

	.accept-btn:hover {
		border: 1px solid ${props => props.theme.grey};
		color: ${props => props.theme.grey};
	}

	.close-btn {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 60px;
		height: 60px;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		${media.tabletLandscapeAndBelow`
			width: 35px;
			height: 50px;
		`}
	}

	.close-large {
		width: 11px;
		height: 11px;
		display: block;
		transition: fill 0.2s ease-in-out;
		${media.tabletLandscapeAndBelow`
		  display: none;
		`}
	}

	.close-btn:hover .close-large{
		fill: ${props => props.theme.grey}
	}

	.close-small {
		width: 9px;
		height: 9px;
		display: none;
		${media.tabletLandscapeAndBelow`
		  display: block;
		`}
	}
`;

export default AnalyticsStyles;
