// ****************************************/
// Footer
// ****************************************/

import styled from 'styled-components';
import { font, media } from '../Styles';

const FooterStyles = styled.footer`
  position: fixed;
  z-index: 2;

  &:after {
    position: relative;
    display: block;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 31.4rem;
    background: transparent;
    pointer-events: none;
    ${media.mobileOnly`
			height: 56.6vw;
		`}
  }

  .wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 31.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: ${props => props.theme.black}
    text-align: center;
    ${media.mobileOnly`
			height: 56.6vw;
		`}
  }

  a {
    display: block;
    font-size: ${font.h2};
    color: white;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.h2Mobile};
		`}
  }

  a:hover {
    color: ${props => props.theme.grey};
  }
`;

export default FooterStyles;
