import React from 'react';

const VideoEmbed = ({ embed }) => {
  const rawMarkup = props => {
    const markup = props;
    return { __html: markup };
  };

  return <section className="iframe-wrapper" dangerouslySetInnerHTML={embed && rawMarkup(embed)} />;
};

export default VideoEmbed;
