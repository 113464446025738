import React, { useContext } from 'react';
import { useObserver, observer } from 'mobx-react-lite';

// Usage
//
//  export default Inject([
//   {
//     appStore: (appStore, ownProps) => ({
//       title: appStore.title
//     })
//   },
//   {
//     typeStore: (typeStore, ownProps) => ({
//       title2: typeStore.title
//     })
//   }
// ])(Buy);

const store = [];

const setStore = (key, newStore) => {
  store[key] = newStore;
};

const Inject = selector => baseComponent => {
  const component = ownProps => {
    if (store.length !== 0) {
      throw new Error('Please, use setStore before your ReactDOM.render call');
    }

    const newStore = useContext(store.appStore);

    return useObserver(() => baseComponent({ ...ownProps, ...selector(newStore, ownProps) }));
  };

  component.displayName = baseComponent.name;
  return component;
};

export { Inject, setStore };
