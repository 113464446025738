import React, { useEffect, useState } from 'react';
import ProjectCard from './ProjectCard';
import { Inject } from '../../lib/Inject';

app = window.app = window.app || {};

function ProjectCardController({
  cards,
  height,
  viewportH,
  cardWipeUri,
  isProjectPage,
  firstCard,
  lastProjectUri
}) {
  const [selected, setSelected] = useState();
  useEffect(() => {
    if (!isProjectPage && selected) {
      clear();
    }

    app.events.on('card.trigger', uri => {
      setSelected(cardWipeUri);
      app.events.publish('card.set');
    });
  });

  const clear = () => {
    setSelected(undefined);
  };

  const item = selected && selected === lastProjectUri ? firstCard : cards[selected];
  if (!item) {
    return null;
  }

  return (
    <ProjectCard
      heightStart={100 - (height / viewportH) * 100}
      onComplete={clear}
      colour={item.colour.rgb}
      description={item.projectDescription}
    />
  );
}

export default Inject((store, ownProps) => ({
  cardWipeUri: store.cardWipeUri,
  lastProjectUri: store.lastProjectUri,
  isProjectPage: store.isProjectPage
}))(ProjectCardController);
