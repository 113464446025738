// ****************************************/
// Typography
// ****************************************/
//
// p {
// 	font-size: ${font.p};
// 	${media.smallDesktopAndBelow`
// 		font-size: ${font.pMobile};
// 	`};
// };
//

const font = {
  family: "Post Grotesk Light, sans-serif",
  logo: "8.4rem",
  logoMobile: "48px",
  h1: "3.2rem",
  h1Tablet: "26px",
  h1Mobile: "21px",
  h2: "2.4rem",
  h2Tablet: "22px",
  h2Mobile: "16px",
  p: "1.8rem",
  pMobile: "16px",
  smallCaps: "2.2rem",
  smallCapsMobile: "16px",
};

export default font;
