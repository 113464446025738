import React, { Suspense } from "react";
// import { Query } from "react-apollo";
import CookiePolicyStyles from "./CookiePolicy.styled";
import GetCookiePolicy from "../../queries/GET_COOKIE_POLICY.graphql";
import { Inject } from "../../lib/Inject";
import fetch from "../../lib/fetch";

function CookiePolicyQuery({ setInverse, setFill, setIsProjectPage }) {
  const href = `/api/cookie-policy.json`;

  const response = fetch({
    href,
    method: "GET",
    body: null,
    cache: "no-cache"
  });

  setInverse(false);
  setFill(true);
  setIsProjectPage(false);

  const rawMarkup = props => {
    const markup = props;
    return { __html: markup };
  };

  const { title, cookiePolicy } = response;

  return (
    <CookiePolicyStyles className="cookie-policy">
      <section>
        <h1>{title}</h1>
        <section className="block-text">
          <p
            dangerouslySetInnerHTML={
              cookiePolicy && rawMarkup(cookiePolicy.content)
            }
          />
        </section>
      </section>
    </CookiePolicyStyles>
  );
}

function CookiePolicy({ setInverse, setFill, setIsProjectPage }) {
  return (
    <Suspense fallback={null}>
      <CookiePolicyQuery
        setInverse={setFill}
        setFill={setFill}
        setIsProjectPage={setIsProjectPage}
      />
    </Suspense>
  );
}

export default Inject((store, ownProps) => ({
  setInverse: store.setInverse,
  setFill: store.setFill,
  setIsProjectPage: store.setIsProjectPage
}))(CookiePolicy);
