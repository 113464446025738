import React, { useEffect, useState, useRef } from 'react';
import TextBlockStyles from './TextBlock.styled';

require('intersection-observer');

function TextBlock({ block, threshold, isAnimated }) {
  let observer;
  const elem = useRef();

  const { position, text, textSize } = block;
  const [isActive, setIsActive] = useState(false);
  const config = {
    rootMargin: '0px',
    threshold: threshold !== undefined ? threshold : 0
  };

  const rawMarkup = props => {
    const markup = props;
    return { __html: markup };
  };

  let style;
  if (isAnimated) {
    if (isActive) {
      style = 'animate is-animated';
    } else {
      style = 'is-animated';
    }
  }

  const onIntersection = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (!isActive) {
          makeActive();
        }
        removeObserver();
      }
    });
  };

  const createObserver = () => {
    if (!elem.current) {
      return;
    }
    observer = new IntersectionObserver(entries => {
      onIntersection(entries);
    }, config);

    observer.observe(elem.current);
  };

  const removeObserver = () => {
    if (observer && elem.current) {
      observer.unobserve(elem.current);
    }
    observer = undefined;
  };

  useEffect(() => {
    createObserver();
    return () => {
      removeObserver();
    };
  }, []);

  const makeActive = () => {
    setIsActive(true);
  };

  const makeInactive = () => {
    setIsActive(false);
  };

  return (
    <TextBlockStyles
      className={`text-block ${textSize && textSize} ${position && position}`}
      ref={elem}
    >
      <p className={style} dangerouslySetInnerHTML={text && rawMarkup(text.content)} />
    </TextBlockStyles>
  );
}

export default React.memo(TextBlock);
