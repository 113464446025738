/**
 * Collection of utility functions
 *
 *
 */

const FormatTitleCase = str => {
  const cleaned = str.replace(/-/g, ' '); // remove hyphen
  return cleaned.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

const ClosestNumber = (array, value) => array.reduce((prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev));

const ClosestLowerNumber = (array, value) => {
  let closest;
  array.some(a => {
    if (a >= value) {
      return true;
    }
    closest = a;
  });
  return closest;
};

const ConvertToRange = (value, srcRange, dstRange) => {
  if (value < srcRange[0]) {
    return dstRange[0];
  }
  if (value > srcRange[1]) {
    return dstRange[1];
  }
  const srcMax = srcRange[1] - srcRange[0];
  const dstMax = dstRange[1] - dstRange[0];
  const adjValue = value - srcRange[0];
  return (adjValue * dstMax) / srcMax + dstRange[0];
};

// debounce limits rate function can fire
//
// https://gist.github.com/nmsdvid/8807205
const Debounce = (callback, time = 250, interval) => (...args) => {
  clearTimeout(interval);
  interval = setTimeout(() => callback(...args), time);
};

const IsNumeric = n => !Number.isNaN(parseFloat(n)) && Number.isFinite(n) && typeof n === 'number';

const GetRandomInt = (min, max) => {
  const min2 = Math.ceil(min);
  const max2 = Math.floor(max);
  return Math.floor(Math.random() * (max2 - min2 + 1)) + min2;
};

const Between = (value, a, b, inclusive) => {
  const min = Math.min(a, b);
  const max = Math.max(a, b);

  return inclusive ? value >= min && value <= max : value > min && value < max;
};

export {
  FormatTitleCase,
  ClosestNumber,
  ClosestLowerNumber,
  ConvertToRange,
  Debounce,
  IsNumeric,
  GetRandomInt,
  Between
};
