// ****************************************/
// Image Block
// ****************************************/

import styled from "styled-components";
import { font, media, animation } from "../Styles";

const ImageBlockStyles = styled.section`
  text-align: center;
  z-index: 3;
  background: ${(props) => props.theme.white};

  &.featured {
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 100vh;
    opacity: 1;
    ${media.tabletPortraitAndBelow`
			position: relative;
			height: 100vw;
		`}
  }

  &.featured.is-hero-scrolled {
    z-index: 2;
    opacity: 0;
  }

  &.featured figure {
    padding-bottom: initial !important;
    height: 100vh;
    ${media.tabletPortraitAndBelow`
			height: 100vw;
		`}
  }

  &.featured picture,
  &.featured img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 2;
  }

  & figure {
    position: relative;
    min-height: 1px;
  }

  & picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.has-margin {
    margin: 0 5.5rem;
    ${media.tabletLandscapeAndBelow`
			margin-left: 60px;
			margin-right: 60px;
		`}
    ${media.mobileOnly`
			margin-left: 0;
			margin-right: 0;
			padding-left: 20px;
			padding-right: 20px;
		`}
  }

  &.right,
  &.has-margin.right {
    margin-left: auto;
    ${media.mobileOnly`
			padding-right: 20px;
			padding-left: 20px;
			margin-right: auto;
		`}
  }

  &.left {
    ${media.mobileOnly`
			padding-right: 20px;
			padding-left: 20px;
		`}
  }

  p {
    font-size: ${font.p};
    line-height: 1.33;
    margin-top: 2.6rem;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.pMobile};
			margin-top: 13px;
		`}
    ${media.mobileOnly`
			margin-top: 6px;
		`}
  }

  p a {
    color: ${(props) => props.theme.grey};
  }

  ${animation.fadeIn}

  figure.is-animated.animate {
    animation: fadeIn 0.4s ease-in-out;
  }
`;

export default ImageBlockStyles;
