import React, { useContext, Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, theme } from "../Styles";
// import Meta from "../Meta";
import Analytics from "../Analytics";
import AppStore from "../../stores/AppStore";
import { setStore } from "../../lib/Inject";

setStore("appStore", AppStore);

const ThemeWrapper = props => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
);

const Page = props => (
  <ThemeWrapper>
    <>
      {navigator.doNotTrack ? null : <Analytics />}
      {/* <Meta /> */}
      <GlobalStyles />
      {props.children}
    </>
  </ThemeWrapper>
);

export default Page;
