// ****************************************/
// Image
// ****************************************/

import styled from 'styled-components';

const FigureStyles = styled.figure`
  width: 100%;
  height: 100%;
`;

const Picture = styled.picture`
  position: relative;
  display: block;
`;

const ImageStyles = styled.img`
  width: 100%;
`;

export { FigureStyles, Picture, ImageStyles };
