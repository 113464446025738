import React, { Suspense, useEffect, useState } from "react";
// import { Query } from 'react-apollo';
import ReactDelayRender from "react-delay-render";
import FooterStyles from "./Footer.styled";
import fetch from "../../lib/fetch";

function FooterContainer() {
  const isHome = window.location.pathname.split("/")[1] === "";
  const [isCurrentHome, setIsCurrentHome] = useState(isHome);
  const href = "/api/footer.json";
  let footerSwitchTimeout;
  const response = fetch({
    href,
    method: "GET",
    body: null,
    cache: "no-cache"
  });

  const { footerEmailAddress, homepageFooter } = response;

  useEffect(() => {
    clearTimeout(footerSwitchTimeout);
    footerSwitchTimeout = setTimeout(() => {
      setIsCurrentHome(isHome);
    }, 1000);
  }, []);

  const rawMarkup = props => {
    const markup = props;
    return { __html: markup };
  };

  return (
    <FooterStyles role="contentinfo">
      <section className="wrapper">
        <p
          dangerouslySetInnerHTML={
            !isCurrentHome
              ? footerEmailAddress && rawMarkup(footerEmailAddress.content)
              : homepageFooter && rawMarkup(homepageFooter.content)
          }
        />
      </section>
    </FooterStyles>
  );
}

function Footer() {
  return (
    <Suspense fallback={null}>
      <FooterContainer />
    </Suspense>
  );
}

export default ReactDelayRender({ delay: 1000 })(Footer);
