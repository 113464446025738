import React, { useEffect, useState } from 'react';
import { useSpring } from 'react-spring';
import ReactGA from 'react-ga';
import AnalyticsStyles from './Analytics.styled';

function Analytics() {
  const [isActive, setIsActive] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);

  useEffect(() => {
    const { hasSeen, hasPreviouslyAccepted } = getLocal();
    if (isActive && (hasAccepted || hasPreviouslyAccepted)) {
      ReactGA.initialize('UA-60744658-1');
      setIsActive(false);
      return;
    }
    if (hasSeen) {
      setIsActive(false);
      return;
    }
    setTimeout(() => {
      setIsActive(true);
    }, 6000);
  }, [isActive]);

  const accept = () => {
    setIsActive(false);
    setHasAccepted(true);
    save({
      hasSeen: true,
      hasPreviouslyAccepted: true
    });
  };

  const reject = () => {
    setIsActive(false);
    save({
      hasSeen: true,
      hasPreviouslyAccepted: false
    });
  };

  const save = data => {
    if (!window.localStorage || !data) {
      return;
    }
    localStorage.POSTPopUp = JSON.stringify(data);
  };

  const getLocal = () => {
    if (!window.localStorage || !localStorage.POSTPopUp) {
      return { hasSeen: false, hasPreviouslyAccepted: false };
    }
    const obj = JSON.parse(localStorage.POSTPopUp);
    return obj;
  };

  const props = useSpring({
    bottom: isActive ? '0px' : '-60px',
    opacity: isActive ? 1 : 0
  });

  return (
    <AnalyticsStyles style={props}>
      <section>
        <p>
          We use cookies to enhance your experience.{' '}
          <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
        </p>
        <button onClick={accept} className="accept-btn">
          Accept
        </button>
      </section>
      <button onClick={reject} className="close-btn">
        <svg
          className="close-large"
          xmlns="http://www.w3.org/2000/svg"
          width="11.71"
          height="11.71"
          viewBox="0 0 11.71 11.71"
        >
          <polygon
            points="11.71 0.71 11 0 5.85 5.15 0.71 0 0 0.71 5.15 5.85 0 11 0.71 11.71 5.85 6.56 11 11.71 11.71 11 6.56 5.85 11.71 0.71"
            fill="#fff"
          />
        </svg>
        <svg
          className="close-small"
          xmlns="http://www.w3.org/2000/svg"
          width="9.71"
          height="9.71"
          viewBox="0 0 9.71 9.71"
        >
          <polygon
            points="9.71 0.71 9 0 4.85 4.15 0.71 0 0 0.71 4.15 4.85 0 9 0.71 9.71 4.85 5.56 9 9.71 9.71 9 5.56 4.85 9.71 0.71"
            fill="#fff"
          />
        </svg>
      </button>
    </AnalyticsStyles>
  );
}

export default Analytics;
