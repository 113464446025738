import React, { useEffect, Suspense } from "react";
import ContactStyles from "./Contact.styled";
import { Inject } from "../../lib/Inject";
import fetch from "../../lib/fetch";

function ContactContainer({ setInverse, setFill, setIsProjectPage }) {
  useEffect(() => {
    setInverse(true);
    return () => {
      setInverse(false);
    };
  }, []);

  setInverse(true);
  setFill(false);
  setIsProjectPage(false);
  const href = `/api/contact.json`;

  const response = fetch({
    href,
    method: "GET",
    body: null,
    cache: "no-cache"
  });

  const { contact } = response;

  const rawMarkup = props => {
    const markup = props;
    return { __html: markup };
  };

  return (
    <ContactStyles className="contact">
      <section className="colophon-block">
        {contact.map((block, i) => (
          <section className="block-text" key={i}>
            <p dangerouslySetInnerHTML={block && rawMarkup(block.content)} />
          </section>
        ))}
      </section>
    </ContactStyles>
  );
}

function Contact({ setFill, setInverse, setIsProjectPage }) {
  return (
    <Suspense fallback={null}>
      <ContactContainer
        setFill={setFill}
        setInverse={setInverse}
        setIsProjectPage={setIsProjectPage}
      />
    </Suspense>
  );
}

export default Inject((store, ownProps) => ({
  setInverse: store.setInverse,
  setFill: store.setFill,
  setIsProjectPage: store.setIsProjectPage
}))(Contact);
