import React, { useEffect, useState } from 'react';
import { Debounce, ClosestLowerNumber } from '../../lib/Utils';
import { Inject } from '../../lib/Inject';
import useWindowSize from '../../lib/useWindowSize';

function HomeScrollSnap({
  elem, inverseArray, isInverse, setInverse
}) {
  const [inverse, setState] = useState(isInverse);
  let thresholds = [];
  const { viewportH } = useWindowSize();
  let scrollTop = 0;

  const getThresholds = () => {
    const children = [...elem.children];
    return children.reduce((array, child, i) => {
      const rect = child.getBoundingClientRect();
      array.push(rect.top + scrollTop);
      return array;
    }, []);
  };

  const switchHeader = i => {
    if (inverseArray[i] === true) {
      setInverse(true);
      return;
    }
    if (inverseArray[i] === false) {
      setInverse(false);
    }
  };

  const checkHeaderInverse = () => {
    if (!thresholds.length) {
      return;
    }
    const scroll = ClosestLowerNumber(thresholds, scrollTop);
    if (scroll === undefined) {
      return;
    }

    thresholds.map((threshold, i) => {
      if (scrollTop >= threshold && scrollTop < threshold + viewportH) {
        switchHeader(i);
      }
    });
  };

  const onScroll = () => {
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    thresholds = getThresholds();
    checkHeaderInverse();
  };

  useEffect(() => {
    if (elem) {
      window.addEventListener('scroll', onScroll, {
        capture: true,
        passive: true
      });
    }
    return () => {
      window.removeEventListener('scroll', onScroll, {
        capture: true,
        passive: true
      });
    };
  }, [elem]);

  return null;
}
export default Inject((store, ownProps) => ({
  isInverse: store.isInverse,
  setInverse: store.setInverse
}))(HomeScrollSnap);
