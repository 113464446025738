import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { animated, Spring } from 'react-spring/renderprops';
import { withRouter, Link } from 'react-router-dom';
import ImageBlock from '../ImageBlock';
import ImageWideBlockStyles from './ImageWideBlock.styled';
import { Inject } from '../../lib/Inject';

app = window.app = window.app || {};

function ImageWideBlock({
  uri, title, wideFormatImageImager, colour, setCardWipeStart, history
}) {
  if (!wideFormatImageImager.length || !title) {
    return null;
  }

  const caption = `Next Project — ${title}`;
  const background = !colour || colour.rgb === 'rgb(255,255,255)' ? '#000000' : colour.rgb;

  const createBackgroundSection = val => {
    const section = styled.section`
      background: ${background};
    `;
    return section;
  };

  const Section = createBackgroundSection(colour);

  const delay = e => {
    e.preventDefault();
    app.events.once('card.set', () => {
      setCardWipeStart(true);
      setTimeout(() => {
        history.push(`/${uri}`);
      }, 700);
    });
    app.events.publish('card.trigger', uri);
  };

  return (
    <ImageWideBlockStyles className="image-wide-block">
      <>
        <Section className="wrapper">
          <ImageBlock
            imageImager={wideFormatImageImager}
            caption={caption}
            hasMargin={false}
            noLazyLoad={false}
          />
          <Link to={`/${uri}`} onClick={delay} onTouchStart={delay} className="caption-container">
            <p>{caption}</p>
          </Link>
        </Section>
      </>
    </ImageWideBlockStyles>
  );
}

export default Inject((store, ownProps) => ({
  setCardWipeStart: store.setCardWipeStart
}))(withRouter(ImageWideBlock));
