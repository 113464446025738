import React from "react";
import { render } from "react-dom";
// import { ApolloProvider } from "react-apollo";
import Events from "event-pubsub";
// import client from "./lib/ApolloClient";
import Router from "./components/Router";
import Page from "./components/Page";

app = window.app = window.app || {};
app.events = new Events();

if (module.hot) {
  module.hot.accept();
}

const App = () => (
  <Page>
    <Router />
  </Page>
);

render(<App />, document.querySelector(".main"));
