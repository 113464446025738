const RouterClickHandler = (e, history) => {
  if (!e || !e.target || !e.target.href) {
    return;
  }

  const { target } = e;
  const { href } = e.target;

  // Ignore canceled events, modified clicks, and right clicks.
  if (e.defaultPrevented) {
    return;
  }

  // Ignore the click if it's a download link. (We use this method of
  // detecting the presence of the attribute for old IE versions.)
  if (target.attributes.download) {
    return;
  }

  // outside links
  if (href.indexOf(document.domain) === -1 || target === '_blank') {
    return;
  }

  // mailto link
  if (href.length > 7 && href.substring(0, 6) === 'mailto') {
    return;
  }

  e.preventDefault();
  e.stopPropagation();

  history.push(target.attributes[0].value);
};

export default RouterClickHandler;
