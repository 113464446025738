import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VideoBlockStyles from './VideoBlock.styled';
import Video from '../Video';

const VideoBlock = React.forwardRef((props, ref) => {
  const {
    inView,
    vimeoLink,
    vimeoEmbed,
    hasMargin,
    alignment,
    videoCaption,
    name,
    fill,
    autoplay,
    controls,
    placeholder,
    customPlayText,
    customPauseText,
    playOnHover,
    playFullscreen
  } = props;

  const rawMarkup = text => {
    const markup = text;
    return { __html: markup };
  };

  return (
    <VideoBlockStyles
      className={`video-block ${hasMargin ? 'has-margin' : ''} ${alignment && alignment} ${
        fill ? 'is-filled' : 'not-filled'
      } ${name || ''}`}
      ref={ref}
    >
      <Video
        inView={inView}
        posterFillMode={fill ? 'cover' : null}
        placeholder={placeholder}
        link={vimeoLink}
        embed={vimeoEmbed}
        hd={true}
        controls={controls}
        autoplay={autoplay || false}
        loop={true}
        fill={fill}
        threshold={0.5}
        controls={controls}
        customPlayText={customPlayText}
        customPauseText={customPauseText}
        playOnHover={playOnHover}
        playFullscreen={playFullscreen}
      />
      {videoCaption ? (
        <p dangerouslySetInnerHTML={videoCaption && rawMarkup(videoCaption.content)} />
      ) : null}
    </VideoBlockStyles>
  );
});

export default VideoBlock;
