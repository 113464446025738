import React, { useEffect, useState } from 'react';

function getSize() {
  const [html, body] = [document.documentElement, document.body];
  return {
    viewportW: Math.max(
      window.innerWidth || html.clientWidth || body.clientWidth || window.screen.availWidth
    ),
    viewportH: Math.max(
      window.innerHeight || html.clientHeight || body.clientHeight || window.screen.availHeight
    )
  };
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

export default useWindowSize;
