import React, { useRef, useState, useEffect } from "react";
import Flickity from "flickity";
import CarouselStyles from "./Carousel.styled";
import Image from "../Image";
import useIntersectionObserver from "../../lib/useIntersectionObserver";
import { Debounce, IsNumeric, Between } from "../../lib/Utils";
import { Inject } from "../../lib/Inject";
import useWindowSize from "../../lib/useWindowSize";

function Carousel({ layout, caption }) {
  const elem = useRef();
  const { viewportW } = useWindowSize();
  let flickity;
  const dragStart = 0;
  const dragEnd = 0;
  const [selectedIndex0, setSelectedIndex0] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const config = {
    rootMargin: "0px",
    threshold: 0
  };
  const isOneTimeActive = useIntersectionObserver({
    elem,
    config,
    isOnce: true
  });
  const isIndexChanged = false;
  const moveVectorX = 0;
  let current = 0;

  useEffect(() => {
    flickity = new Flickity(elem.current, {
      cellAlign: "center",
      wrapAround: false,
      prevNextButtons: false
    });

    flickity.on("change", () => {
      let index = flickity.selectedIndex + 1;
      if (index > layout.length) {
        index = 1;
      }
      setSelectedIndex(index);
    });

    flickity.on("staticClick", (event, pointer, cellElement, cellIndex) => {
      if (cellIndex === undefined) {
        return;
      }
      select(cellIndex);
    });
  }, []);

  const next = () => {
    flickity.next();
  };

  const previous = () => {
    flickity.previous();
  };

  const select = i => {
    if (!flickity) {
      console.log("no flickity");
      return;
    }
    if (i === current) {
      return;
    }
    if (i > current) {
      next();
      current = i;
      return;
    }
    current = i;
    previous();
  };

  const shouldForceLoad = (i, selected) => {
    const adjCount = 2;
    const index = i + 1;
    const { length } = layout;
    const lower = selected - adjCount;
    const upper = selected + adjCount;

    // don't lazy load if adjacent cells are more than the length
    if (1 + adjCount * 2 >= length) {
      return true;
    }

    if (Between(index, lower, upper, true)) {
      return true;
    }

    return false;
  };

  const rawMarkup = value => {
    const markup = value;
    return { __html: markup };
  };

  return (
    <CarouselStyles
      className={`layout is-animated ${isOneTimeActive ? "animate" : ""} ${
        caption ? "has-caption" : ""
      }`}
    >
      <section ref={elem} className="flickity-slider">
        {layout.map((slide, i) => {
          if (slide.length && slide[0].url) {
            return (
              <section key={i} className="carousel-cell">
                <Image
                  noLazyLoad={shouldForceLoad(i, selectedIndex)}
                  alt={slide[0].filename}
                  src={slide[0].url}
                  srcMobile={slide[1].url}
                  // srcWebP={slide[2].url}
                  width={slide[0].width}
                  height={slide[0].height}
                />
              </section>
            );
          }
        })}
      </section>
      <section className="caption-container">
        <span className="counter">
          {caption ? `${caption} – ` : null}
          {selectedIndex}/{layout ? layout.length : 1}
        </span>
      </section>
    </CarouselStyles>
  );
}

export default Inject((store, ownProps) => ({
  viewportW: store.viewportW
}))(Carousel);
