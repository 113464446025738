import React, { useContext, useEffect, Suspense } from "react";
// import { Query } from "react-apollo";
import AboutStyles from "./About.styled";
import ImageBlock from "../ImageBlock";
import TextBlock from "../TextBlock";
import { Inject } from "../../lib/Inject";
import Footer from "../Footer";
import fetch from "../../lib/fetch";

let scrollTimeout;

function AboutQueryContainer({ setFill, setInverse, setIsProjectPage }) {
  const href = `/api/about.json`;

  const response = fetch({
    href,
    method: "GET",
    body: null,
    cache: "no-cache"
  });

  const renderBlock = (block, i) => {
    if (block.image) {
      const props = Object.assign({}, block.image, { name: "regular" });
      return <ImageBlock {...props} key={i} />;
    }
    if (block.text) {
      return (
        <TextBlock block={block.text} key={i} threshold={0} isAnimated={true} />
      );
    }
    return null;
  };

  useEffect(() => {
    setIsProjectPage(false);
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      setFill(true);
      setInverse(false);
    }, 450);
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, []);

  const { aboutDescription, layout } = response;

  return (
    <>
      <AboutStyles className="about">
        <section className="intro">
          <h2>{aboutDescription}</h2>
        </section>
        {layout.map((block, i) => renderBlock(block, i))}
      </AboutStyles>
      <Footer />
    </>
  );
}

function AboutContainer({ setFill, setInverse, setIsProjectPage }) {
  return (
    <Suspense fallback={null}>
      <AboutQueryContainer
        setInverse={setInverse}
        setFill={setFill}
        setIsProjectPage={setIsProjectPage}
      />
    </Suspense>
  );
}

export default Inject((store, ownProps) => ({
  setFill: store.setFill,
  setInverse: store.setInverse,
  setIsProjectPage: store.setIsProjectPage
}))(AboutContainer);
