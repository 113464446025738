// ****************************************/
// Contact
// ****************************************/

import styled from "styled-components";
import { font, media, animation } from "../Styles";

const HomeStyles = styled.section`
  overflow-y: scroll;
	position: relative;
	z-index: 3;
	margin-bottom: 31.4rem;
	min-height: 100vh;
	${media.mobileOnly`
		margin-bottom: 56.6vw;
	`}

	.video-block {
		background: ${(props) => props.theme.black}
		z-index: 3;
	}

	.image-block, .video-block {
		position: relative;
		min-height: 1px;
		height: 100vh;
		display: flex;
  	align-items: center;
  	justify-content: center;
		${media.mobileOnly`
			height: 100vw;
		`}
	}

	.image-block section {
		width: 100%;
		height: 100%;
	}

	.image-block figure {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
		padding: 0
	}

	.image-block figure img,
	.image-block figure picture{
		width: 100%;
    height: 100%;
    object-fit: cover;
	}

	.video-block {
		transition: background 0.25s ease-in-out;
		${media.tabletPortraitAndBelow`
			height: 56.25vw;
		`}
	}

	.video-block figure {
		padding-bottom: initial !important;
		transition: background 0.25s ease-in-out;
	}

	.video-block.not-filled figure{
		width: 102.8rem;
    height: 68.533rem;
		margin: auto;
		background-size: contain;
		${media.tabletPortraitAndBelow`
			width: 100%;
			height: 100%;
		`}
	}

	.video-block .iframe-wrapper iframe {
		position: absolute;
		background: ${(props) => props.theme.black}
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100vw;
   	height: 56.25vw;
   	min-height: 100vh;
   	min-width: 177.77vh;
		${media.mobileOnly`
			height: 50vw;
			min-height: 50vh;
			min-width: 100vw;
		`}
	}

  .text-block,
  .text-block-intro{
		position: relative;
    width: 100%;
    height: 100vh;
    background: ${(props) => props.theme.white};;
    text-align: center;
		z-index: 3;
    ${media.tabletLandscapeAndBelow`
			padding-left: 80px;
			padding-right: 80px;
		`}
		${media.tabletPortraitAndBelow`
			padding-left: 60px;
			padding-right: 60px;
		`}
		${media.mobileOnly`
			padding-left: 20px;
			padding-right: 20px;
			height: 88vh;
		`}
  }

	& > .text-block:nth-child(1){
		${media.mobileOnly`
			height: 100vh;
		`}
	}

	.text-block{
    display: flex;
		flex-direction: column;
    align-items: center;
    justify-content: center;
	}

	.text-block-intro > section{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
	}

	.text-block-intro > section{
		${media.tabletLandscapeAndBelow`
			width: 100%;
			padding-left: 80px;
			padding-right: 80px;
		`}
		${media.tabletPortraitAndBelow`
			padding-left: 60px;
			padding-right: 60px;
		`}
		${media.mobileOnly`
			padding-left: 20px;
			padding-right: 20px;
		`}
	}

	.text-block-intro section p {
		perspective: 1000px;
		backface-visibility: hidden;
	}

	h1 {
		font-size: ${font.logo};
		text-transform: uppercase;
		letter-spacing: 2px;
		${media.tabletLandscapeAndBelow`
			font-size: ${font.logoMobile};
		`}
	}

  p {
    font-size: ${font.h1};
    width: 90rem;
    line-height: 1.333;
		color: inherit;
    ${media.smallDesktopAndBelow`
			width: 750px;
		`}
    ${media.tabletLandscapeAndBelow`
			width: auto;
			font-size: ${font.h1Mobile};
		`}
  }

	.cta {
		margin-top: 2rem;
		${media.tabletLandscapeAndBelow`
			margin-top: 20px;
		`}
	}

	.cta a {
		color: ${(props) => props.theme.grey};
	}

	.transition-item{
		position: relative;
		backface-visibility: hidden;
	}

	${animation.fadeIn}

	p.is-animated:not(.animate){
		opacity: 0;
	}

	p.is-animated.animate {
		animation: fadeIn 1s ease-in-out;
	}

	video {
		opacity: 0;
	}

	&.is-video-fullscreen .video-block {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100vh;
		background: black;
		z-index: 10;
	}

	&.is-video-fullscreen .video-block video {
		object-fit: contain;
	}

	&.is-video-fullscreen .video-block figure {
		background: black;
	}

`;

export default HomeStyles;
