import React, { useState, useEffect } from 'react';
import { Transition, animated } from 'react-spring/renderprops';
import { Switch, Route } from 'react-router-dom';
import Contact from '../../pages/Contact';
import Projects from '../../pages/Projects';
import About from '../../pages/About';
import Home from '../../pages/Home';
import ProjectDetail from '../../pages/ProjectDetail';
import CookiePolicy from '../../pages/CookiePolicy';

let scrollTimeout;

function TransitionFade({ location }) {
  return (
    <Transition
      items={location}
      keys={loc => loc.pathname}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      onStart={() => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          window.scroll(0, 0);
        }, 450);
      }}
      onDestroyed={clearTimeout(scrollTimeout)}
      native
    >
      {(loc, state) => props => (
        <animated.section style={props}>
          <Switch location={loc}>
            <Route exact path="/contact" component={Contact} />
            {location.pathname.match(/admin\/entries\/contact(.+)/g) ? (
              <Route component={Contact} isAdmin={true} />
            ) : null}
            <Route exact path="/projects" component={Projects} />
            <Route path="/projects/:id" component={ProjectDetail} />
            {location.pathname.match(/admin\/entries\/projects(.+)/g) ? (
              <Route component={ProjectDetail} isAdmin={true} />
            ) : null}
            <Route exact path="/about" component={About} />
            {location.pathname.match(/admin\/entries\/about\/(.+)/g) ? (
              <Route component={About} isAdmin={true} />
            ) : null}
            <Route exact path="/cookie-policy" component={CookiePolicy} />
            {location.pathname.match(/admin\/entries\/cookiePolicy\/(.+)/g) ? (
              <Route component={CookiePolicy} isAdmin={true} />
            ) : null}
            <Route exact path="/" component={Home} />
            {location.pathname.match(/admin\/entries\/home\/(.+)/g) ? (
              <Route component={Home} isAdmin={true} />
            ) : null}
          </Switch>
        </animated.section>
      )}
    </Transition>
  );
}

export default TransitionFade;
