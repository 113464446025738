// ****************************************/
// Image Wide Block
// ****************************************/

import styled from 'styled-components';
import { font, media } from '../Styles';

const ImageWideBlockStyles = styled.section`
  position: relative;

  .image-block {
    pointer-events: none;
    transition: opacity 0.4s ease-in-out;
  }

  &:hover .image-block {
    opacity: 0;
  }

  &:after {
    position: relative;
    display: block;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 25rem;
    max-height: 25rem;
    background: transparent;
    pointer-events: none;
    ${media.tabletLandscapeAndBelow`
			height: 250px;
		`}
    ${media.mobileOnly`
			height: 56.6vw;
			max-height: initial;
		`}
  }

  .wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25rem;
    max-height: 25rem;
    z-index: 2;
    ${media.tabletLandscapeAndBelow`
			height: 250px;
		`}
    ${media.mobileOnly`
			height: 56.6vw;
			max-height: initial;
		`}
  }

  figure {
    position: relative;
    width: 100%;
  }

  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    ${media.tabletLandscapeAndBelow`
			height: 100%;
    	object-fit: cover;
		`}
  }

  .caption-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .caption-container p {
    font-size: ${font.h2};
    color: white;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.h2Mobile};
		`}
  }
`;

export default ImageWideBlockStyles;
