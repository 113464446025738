import React, { useRef, useEffect } from "react";
import Image from "../Image";
import ImageBlockStyles from "./ImageBlock.styled";

const ImageBlock = ({
  title,
  imageImager,
  imageCaption,
  hasMargin,
  position,
  name,
  noAnimation,
  noLazyLoad
}) => {
  const rawMarkup = text => {
    const markup = text;
    return { __html: markup };
  };

  return (
    <ImageBlockStyles
      className={`image-block ${hasMargin ? "has-margin" : ""} ${position ||
        ""} ${name || ""}`}
    >
      <section>
        <>
          <Image
            noLazyLoad={noLazyLoad === true}
            isAnimated={!noAnimation}
            alt={title}
            src={imageImager[0].url}
            srcMobile={imageImager[1].url}
            // srcWebP={imageImager[2].url}
            width={imageImager[0].width}
            height={imageImager[0].height}
          />
          {imageCaption ? (
            <p
              dangerouslySetInnerHTML={
                imageCaption && rawMarkup(imageCaption.content)
              }
            />
          ) : null}
        </>
      </section>
    </ImageBlockStyles>
  );
};

export default ImageBlock;
