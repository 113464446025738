// ****************************************/
// Cookie Policy
// ****************************************/

import styled from "styled-components";
import { font, media } from "../Styles";

const CookiePolicyStyles = styled.section`
  position: relative;
  z-index: 3;
  background: ${(props) => props.theme.white};;
  min-height: 100vh;
  padding: 11.4rem 5.5rem;
  ${media.tabletLandscapeAndBelow`
		padding: 95px 60px;
	`}
  ${media.mobileOnly`
		padding: 84px 20px;
	`}

	& > section {
    margin: auto;
    width: 49%;
    ${media.smallDesktopAndBelow`
			width: 620px;
		`}
    ${media.tabletPortraitAndBelow`
			width: 100%;
		`}
  }

  h1 {
    font-size: ${font.h1};
    margin: 0 auto 10.4rem auto;
    text-align: center;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.h1Tablet};
			margin-bottom: 64px;
		`}
    ${media.mobileOnly`
			font-size: ${font.h1Mobile};
			margin-bottom: 51px;
		`}
  }

  p {
    font-size: ${font.p};
    line-height: 1.33;
    ${media.smallDesktopAndBelow`
			font-size: ${font.pMobile};
		`}
  }

  a {
    display: inline-block;
    color: ${(props) => props.theme.grey};
  }

  strong {
    font-weight: normal;
    -webkit-text-stroke: 0.3px ${(props) => props.theme.black}
    text-stroke: 0.3px ${(props) => props.theme.black}
    margin-bottom: 0.5rem;
    display: block;
    ${media.smallDesktopAndBelow`
			marign-bottom: 5px;
		`}
  }

  strong + br {
    display: none;
  }
`;

export default CookiePolicyStyles;
