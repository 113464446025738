import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  Suspense
} from "react";
// import { Query } from "react-apollo";
import ProjectsStyles from "./Projects.styled";
import Project from "./Project";
import { Inject } from "../../lib/Inject";
import fetch from "../../lib/fetch";
import Footer from "../Footer";

function Projects({ setFill, setInverse, setIsProjectPage }) {
  setFill(true);
  setInverse(false);
  setIsProjectPage(false);

  const href = `/api/projects.json`;

  const response = fetch({
    href,
    method: "GET",
    body: null,
    cache: "no-cache"
  });

  if (!response || !response.data) {
    return null;
  }

  return (
    <>
      <ProjectsStyles className="projects">
        {response.data.map(
          (entry, i) =>
            entry.enabled && (
              <Project
                key={i}
                title={entry.title}
                uri={entry.uri}
                thumbnailImageImager={entry.thumbnailImage.thumbnailImageImager}
                thumbnailVideo={entry.thumbnailVideo}
                projectDescription={entry.projectDescription}
              />
            )
        )}
      </ProjectsStyles>
      <Footer />
    </>
  );
}

function ProjectsContainer({ setFill, setInverse, setIsProjectPage }) {
  return (
    <Suspense fallback={null}>
      <Projects
        setFill={setFill}
        setInverse={setInverse}
        setIsProjectPage={setIsProjectPage}
      />
    </Suspense>
  );
}

export default Inject((store, ownProps) => ({
  setFill: store.setFill,
  setInverse: store.setInverse,
  setIsProjectPage: store.setIsProjectPage
}))(ProjectsContainer);
