import React, {
  Fragment, useState, useRef, useEffect
} from 'react';
import { animated, useSpring, useChain } from 'react-spring';
import delay from 'delay';
import { Debounce, ClosestLowerNumber } from '../../lib/Utils';
import { Inject } from '../../lib/Inject';

function HomeEntryIntro({
  paragraph, setLogoShown, setIsLogoAnimating, isLogoAnimating
}) {
  const rawMarkup = value => {
    const markup = value;
    return { __html: markup };
  };

  const from = {
    transform: 'translate3d(0,50px,0)',
    opacity: 0
  };

  const logoRef = useRef();
  const [logoProps, logoSet, logoStop] = useSpring(() => ({
    from,
    ref: logoRef,
    to: async (next, cancel) => {
      setIsLogoAnimating(true);
      await next({
        transform: 'translate3d(0,0px,0)',
        opacity: 1
      });
      await delay(3500);
      await next({
        transform: 'translate3d(0,-50px,0)',
        opacity: 0
      });
      setIsLogoAnimating(false);
    }
  }));

  const paraRef = useRef();
  const [paraProps, paraSet, paraStop] = useSpring(() => ({
    from,
    ref: paraRef,
    to: {
      transform: 'translate3d(0,0px,0)',
      opacity: 1
    }
  }));

  useChain([logoRef, paraRef]);

  const checkScroll = () => {
    if (isLogoAnimating) {
      setIsLogoAnimating(false);
    }
    window.removeEventListener('scroll', scrollDebounce);
  };

  const scrollDebounce = Debounce(checkScroll, 10);

  useEffect(() => {
    window.addEventListener('scroll', scrollDebounce);
    return () => {
      setIsLogoAnimating(false);
      setLogoShown(false);
      window.removeEventListener('scroll', scrollDebounce);
    };
  }, []);

  return (
    <section className="text-block-intro">
      {isLogoAnimating ? (
        <section>
          <animated.h1 style={logoProps}>Post</animated.h1>
        </section>
      ) : null}
      <section>
        {paragraph && !isLogoAnimating ? (
          <animated.p
            style={paraProps}
            dangerouslySetInnerHTML={paragraph && rawMarkup(paragraph.content)}
          />
        ) : null}
      </section>
    </section>
  );
}

export default Inject((store, ownProps) => ({
  isLogoAnimating: store.isLogoAnimating,
  setIsLogoAnimating: store.setIsLogoAnimating,
  setLogoShown: store.setLogoShown
}))(HomeEntryIntro);
