import React, {
  Fragment, useContext, useState, useEffect, Suspense
} from 'react';
import ProjectCardController from './ProjectCardController';
import { Inject } from '../../lib/Inject';
import fetch from '../../lib/fetch';
import useWindowSize from '../../lib/useWindowSize';

function ProjectCardQuery({ setFirstProject, setLastProject, setLastProjectUri }) {
  const href = '/api/project-cards.json';

  const response = fetch({
    href,
    method: 'GET',
    body: null,
    cache: 'no-cache'
  });

  if (!response) {
    return null;
  }

  const { viewportW, viewportH } = useWindowSize();

  const isMobileDown = viewportW < 520;
  const isTabletDown = viewportW < 1025;

  const [cards, setCards] = useState([]);
  const [cardHeight, setCardHeight] = useState(0);

  const calcHeight = () => {
    const xlHeight = (viewportW / 1440) * 250;
    const tabletHeight = 250;
    const mobileHeight = viewportW * 0.566;

    if (isTabletDown && !isMobileDown) {
      setCardHeight(tabletHeight);
      return;
    }

    if (isMobileDown) {
      setCardHeight(mobileHeight);
      return;
    }

    setCardHeight(xlHeight);
  };

  useEffect(() => {
    calcHeight();
    const activeEntries = response.data.filter(entry => entry.enabled === '1');
    setCards(activeEntries);
    setLastProject(response.data[response.data.length - 1].id);
    setFirstProject(response.data[0].id);
    setLastProjectUri(response.data[response.data.length - 1].uri);
  }, [viewportW, viewportH]);

  const array = [];

  response.data.map((card, i) => {
    const selected = cards[i - 1];
    if (selected) {
      array[selected.uri] = {
        enabled: card.enabled,
        projectDescription: card.projectDescription,
        colour: card.colour
      };
    }
  });

  return (
    <ProjectCardController
      cards={array}
      firstCard={cards[0]}
      height={cardHeight}
      viewportH={viewportH}
    />
  );
}

function ProjectCardContainer({ setFirstProject, setLastProject, setLastProjectUri }) {
  return (
    <Suspense fallback={null}>
      <ProjectCardQuery
        setFirstProject={setFirstProject}
        setLastProject={setLastProject}
        setLastProjectUri={setLastProjectUri}
      />
    </Suspense>
  );
}

export default Inject((store, ownProps) => ({
  setFirstProject: store.setFirstProject,
  setLastProject: store.setLastProject,
  setLastProjectUri: store.setLastProjectUri
}))(ProjectCardContainer);
