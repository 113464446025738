// ****************************************/
// Feed Gallery
// ****************************************/

import styled from 'styled-components';
import {
  media, underline, font, animation
} from '../Styles';

const CarouselStyles = styled.section`
  position: relative;
  margin-top: 11.4rem;
  height: 65rem;
  ${media.tabletLandscapeAndBelow`
		margin-top: 95px;
		height: calc(3/5 * calc(100vw - 120px));
	`}
  ${media.mobileOnly`
		margin-top: 60px;
		height: calc(3/5 * calc(100vw - 40px));
	`}

  &.has-caption {
    margin-bottom: 4.4rem;
    ${media.tabletLandscapeAndBelow`
			margin-bottom: 32px;
		`}
    ${media.mobileOnly`
			margin-bottom: 24px;
		`}
  }

  figure {
    padding-bottom: 0 !important;
  }

  picture,
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
		${media.tabletLandscapeAndBelow`
			object-fit: cover;
		`}
  }

  .flickity-enabled:focus {
    outline: none;
  }

  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  ${animation.fadeIn}

  &.is-animated:not(.animate) {
    opacity: 0;
  }

  &.is-animated.animate {
    animation: fadeIn 1s ease-in-out;
  }

  .carousel-cell {
    width: 108rem;
    margin-right: 5.5rem;
    height: 65rem;
    ${media.tabletLandscapeAndBelow`
			width: calc(100% - 120px);
			margin-right: 30px;
			height: calc(3/5 * calc(100vw - 120px));
		`};
    ${media.mobileOnly`
			width: calc(100% - 40px);
			margin-right: 12px;
			height: calc(3/5 * calc(100vw - 40px));
		`}
  }

  /* draggable */
  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .caption-container {
    position: absolute;
    top: 65rem;
    z-index: 3;
    width: 100%;
    text-align: center;
    margin-top: 2.6rem;
    ${media.tabletLandscapeAndBelow`
			top: 450px;
			top: calc(3/5 * calc(100vw - 120px));
			margin-top: 13px;
		`};
    ${media.mobileOnly`
			top: calc(3/5 * calc(100vw - 40px));
			margin-top: 6px;
		`};
  }

  .caption-container span {
    padding: 0 1.2rem;
  }

  .caption-container span,
  .caption-container span p,
  .caption-container span a {
    display: block;
    font-family: ${font.family};
    font-size: ${font.p};
    letter-spacing: -0.1px;
    color: ${props => props.theme.black}
    white-space: nowrap;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.pMobile};
    `}
  }

  .caption-container span a {
    display: inline;
    color: ${props => props.theme.grey};
  }

  figure {
    pointer-events: none;
  }
`;

export default CarouselStyles;
