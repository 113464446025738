// ****************************************/
// Text Block
// ****************************************/

import styled from "styled-components";
import { font, media, underline, animation } from "../Styles";

const TextBlockStyles = styled.section`
  padding: 11.4rem 5.5rem;
  background: ${(props) => props.theme.white};
  ${media.tabletLandscapeAndBelow`
		padding: 95px 60px;
	`}
  ${media.mobileOnly`
		padding: 61px 20px;
	`}

	p {
    font-size: ${font.h2};
    line-height: 1.33;
    width: 50%;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.h2Tablet}
			width: auto;
		`}
    ${media.mobileOnly`
			font-size: ${font.h2Mobile}
		`}
  }

  &.large p {
    font-size: ${font.h1};
    width: 50%;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.h1Tablet}
		`}
    ${media.mobileOnly`
			font-size: ${font.h1Mobile}
		`}
  }

  &.small p {
    font-size: ${font.p};
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.pMobile}
		`}
  }

  &.right p {
    margin-left: auto;
  }

  &.center {
    text-align: center;
  }

  &.center p {
    margin: auto;
  }

  p.regular a {
    display: inline-block;
  }

  p a:hover {
    color: ${(props) => props.theme.grey};
  }

  ${animation.fadeIn}

  p.is-animated.animate {
    animation: fadeIn 1s ease-in-out;
  }
`;

export default TextBlockStyles;
