// ****************************************/
// Header
// ****************************************/

import styled from "styled-components";
import { animated } from "react-spring";
import { font, media } from "../Styles";

const HeaderStyles = styled(animated.nav)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 2.5rem 5.5rem 2.5rem 5.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  min-height: 60px;
  transition: opacity 0.25 ease-in-out;
  ${media.mobileOnly`
		min-height: 50px;
	`}

  &.is-video-fullscreen,
  &.is-hidden {
    opacity: 0;
  }

  &.is-filled {
    /* background: ${(props) => props.theme.white};; */
  }

  &.is-filled.is-inverse {
    background: transparent;
  }

  h1,
  p,
  a {
    color: ${(props) => props.theme.black};
  }

  &.is-inverse h1,
  &.is-inverse p,
  &.is-inverse a {
    color: white;
  }

  & > section:nth-child(2) {
    text-align: right;
    flex: 1;
  }

  p,
  a {
    font-size: ${font.p};
    letter-spacing: 0.02rem;
    ${media.smallDesktopAndBelow`
 			font-size: ${font.pMobile};
			letter-spacing: 0.2px;
	 	`};
  }

  h1 {
    text-transform: uppercase;
  }

  a {
    display: inline-block;
  }

  a:hover {
    color: ${(props) => props.theme.grey};
  }

  a + a {
    padding-left: 1.5rem;
    ${media.smallDesktopAndBelow`
			padding-left: 15px;
		`}
    ${media.mobileOnly`
			padding-left: 10px;
		`}
  }
`;

export default HeaderStyles;
