// ****************************************/
// Animations
// ****************************************/

import { css } from 'styled-components';

const fadeIn = css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const scaleFadeout = css`
  @keyframes scaleFadeout {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.85);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.2);
    }
  }
`;

const animation = {
  fadeIn,
  scaleFadeout
};

export default animation;
