import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  Suspense
} from "react";
// import { Query } from "react-apollo";
import { Transition, Keyframes, animated } from "react-spring/renderprops";
import delay from "delay";
import { set } from "mobx";
import ProjectDetailStyles from "./ProjectDetail.styled";
import ProjectDetail from "./ProjectDetail";
import { Inject } from "../../lib/Inject";
import fetch from "../../lib/fetch";

app = window.app = window.app || {};

function ProjectDetailQuery({
  setInverse,
  setCardWipeUri,
  cardWipeUri,
  isAdmin,
  lastProject,
  firstProject
}) {
  useEffect(
    () => () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        setCardWipeUri(undefined);
      }, 500);
    },
    []
  );

  const rawSlug = window.location.pathname.split("/").pop();
  const slug = rawSlug.replace(/^([0-9])+(-)/g, "");

  const href = `/api/projects/${slug}.json`;

  const response = fetch({
    href,
    method: "GET",
    body: null,
    cache: "no-cache"
  });

  const {
    inverseHeader,
    colour,
    projectDescription,
    id,
    uri,
    enabled,
    layout,
    title,
    featuredVideo,
    featuredImage
  } = response;

  if (inverseHeader) {
    setInverse(true);
  }

  if (!cardWipeUri) {
    window.scrollTo(0, 0);
    setCardWipeUri(response.uri);
  }

  if (!isAdmin && !enabled) {
    return null;
  }

  return (
    <ProjectDetailStyles className="project-detail">
      <ProjectDetail
        title={title}
        id={parseInt(id, 10)}
        uri={uri}
        featuredImageImager={featuredImage.featuredImageImager}
        featuredVideo={featuredVideo}
        layout={layout}
        inverseHeader={inverseHeader}
        isLastProject={
          lastProject && parseInt(lastProject, 10) === parseInt(id, 10)
        }
        firstProject={firstProject}
      />
    </ProjectDetailStyles>
  );
}

function ProjectDetailContainer({
  setInverse,
  setCardWipeUri,
  cardWipeUri,
  lastProject,
  firstProject,
  isAdmin
}) {
  return (
    <Suspense fallback={null}>
      <ProjectDetailQuery
        setInverse={setInverse}
        setCardWipeUri={setCardWipeUri}
        cardWipeUri={cardWipeUri}
        lastProject={lastProject}
        firstProject={firstProject}
        isAdmin={isAdmin}
      />
    </Suspense>
  );
}

export default Inject((store, ownProps) => ({
  setInverse: store.setInverse,
  setCardWipeUri: store.setCardWipeUri,
  cardWipeUri: store.cardWipeUri,
  lastProject: store.lastProject,
  firstProject: store.firstProject
}))(ProjectDetailContainer);
