import React, { useEffect, useState, useLayoutEffect } from 'react';
import animateScrollTo from 'animated-scroll-to';
import { Debounce, ClosestNumber, ClosestLowerNumber } from '../../lib/Utils';
import useWindowSize from '../../lib/useWindowSize';

function HomeScrollSnap({ elem }) {
  const options = {
    speed: 1000,
    minDuration: 500,
    maxDuration: 1800,
    element: window,
    cancelOnUserAction: true,
    passive: true
  };

  let scrollTimer;
  let scrollTops;
  const { viewportW } = useWindowSize();

  const getScrollTops = scrollTop => {
    if (!elem) {
      return null;
    }
    const children = [...elem.children];
    return children.reduce((array, child, i) => {
      if (child !== children[children.length]) {
        array.push(child.getBoundingClientRect().top + scrollTop);
      }
      return array;
    }, []);
  };

  const scrollTo = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (!elem) {
      return;
    }
    // console.log('scrollTo');

    scrollTops = getScrollTops(scrollTop);
    if (!scrollTops || !scrollTops.length) {
      return;
    }
    const i = scrollTops.indexOf(ClosestNumber(scrollTops, scrollTop));
    if (i === scrollTops.length - 1 && scrollTops[i] < scrollTop) {
      return;
    }
    animateScrollTo(scrollTops[i], options);
  };

  const scrollHandler = () => {
    scrollTimer = setTimeout(() => {
      scrollTo();
    }, 350);
  };

  // const scrollDebounce = Debounce(scrollHandler, 0);

  const onScroll = () => {
    clearTimeout(scrollTimer);

    if (viewportW >= 1024 && elem) {
      scrollHandler();
    }
  };

  useEffect(() => {
    if (!elem) {
      return;
    }
    window.addEventListener('scroll', onScroll, {
      capture: true,
      passive: true
    });
    return () => {
      clearTimeout(scrollTimer);
      window.removeEventListener('scroll', onScroll, {
        capture: true,
        passive: true
      });
    };
  }, [elem]);

  useLayoutEffect(() => {
    if (!elem) {
      return;
    }
    scrollTops = getScrollTops(0);
  }, [elem]);

  return null;
}

export default HomeScrollSnap;
