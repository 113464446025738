// ****************************************/
// Image Block
// ****************************************/

import styled from 'styled-components';
import { font, media, animation } from '../Styles';

const ImageDoubleBlockStyles = styled.section`
  text-align: center;

  &.has-margin > section > section:nth-child(1) {
    margin-right: 2.25rem;
    ${media.tabletLandscapeAndBelow`
			margin-left: auto;
			margin-right: 15px;
		`}
    ${media.tabletPortraitAndBelow`
			margin-left: auto;
			margin-right: auto;
		`}
  }

  &.has-margin > section > section:nth-child(2) {
    margin-left: 2.25rem;
    ${media.tabletLandscapeAndBelow`
			margin-left: 15px;
			margin-right: auto;
		`}
    ${media.tabletPortraitAndBelow`
			margin-left: auto;
			margin-right: auto;
		`}
  }

  &.has-margin {
    margin: 0 5.5rem;
    ${media.tabletLandscapeAndBelow`
			margin: 0 60px;
		`}
    ${media.mobileOnly`
			margin: 0 20px;
		`}
  }

  & > section {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    ${media.tabletLandscapeAndBelow`
			justify-content: space-evenly;
		`}
    ${media.tabletPortraitAndBelow`
			display: block;
			flex-wrap: initial;
			justify-content: initial;
			align-items: initial;
		`}
  }

  & > section > section {
    flex: 1;
  }

  &.has-margin > section > section:nth-child(2) {
    ${media.tabletPortraitAndBelow`
			margin-top: 35px;
		`}
  }

  p {
    font-size: ${font.p};
    line-height: 1.33;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.pMobile};
		`}
  }

  figure {
    position: relative;
  }

  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  p {
    font-size: ${font.p};
    line-height: 1.33;
    margin-top: 2.6rem;
    ${media.tabletLandscapeAndBelow`
			font-size: ${font.pMobile};
			margin-top: 13px;
		`}
    ${media.mobileOnly`
			margin-top: 6px;
		`}
  }

  p a:hover {
    color: ${props => props.theme.grey};
  }

  ${animation.fadeIn}

  figure.is-animated.animate {
    animation: fadeIn 0.4s ease-in-out;
  }
`;

export default ImageDoubleBlockStyles;
